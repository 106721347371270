import React, { useState, useEffect } from "react";
import hero_img from "../../../images/marshall/main-4-marshall.jpeg";
import sales_img from "../../../images/marshall/sales.jpeg";
import tech_img from "../../../images/marshall/tech.jpeg";
import visalegal_img from "../../../images/marshall/visa-legal.jpeg";
import expertKnow from "../../../images/marshall/expertKnow.jpeg";
import BgGlassmorphism from "../../BgGlassmorphism/BgGlassmorphism";
import marshallLogo from "../../../images/marshall/logo.png";
import adansiLogo from "../../../images/logo.png";

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";

// Global Styles
const colors = {
  primary: "white",
  secondary: "#C9E6E8",
  accent: "#EDF3FF",
  darkText: "#000000",
  button: "#000000",
  heroPrimary: "#03844e",
  marshallColor: "#16170b",
  marshallRed: "#742d24",
  marshallBlue: "#343160",
};

// Custom Hook to get window size
function useWindowSize() {
  const isClient = typeof window === "object";

  function getSize() {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
    };
  }

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!isClient) {
      return;
    }

    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [isClient]);

  return windowSize;
}

// Two-Column Section Styling
const getTwoColumnContainerStyle = (isMobile) => ({
  display: "flex",
  alignItems: "center",
  padding: isMobile ? "20px" : "50px", // Adjusted padding for mobile
  backgroundColor: colors.primary,
  flexDirection: isMobile ? "column" : "row",
});

const getLeftColumnStyle = (isMobile) => ({
  flex: 1,
  paddingRight: isMobile ? "0" : "10px",
  textAlign: "left",
});

const getRightColumnStyle = (isMobile) => ({
  flex: 1,
  display: "flex",
  justifyContent: "center",
  marginTop: isMobile ? "20px" : "0",
});

const BenefitsTitle = {
  fontSize: "1.5rem",
  fontWeight: 700,
  color: colors.darkText,
  marginBottom: "20px",
  width: "100%",
  textAlign: "left",
};

const BenefitsList = {
  fontSize: "1rem",
  color: colors.darkText,
  lineHeight: "1.6",
  listStyleType: "disc",
  paddingLeft: "15px",
  marginBottom: "15px",
  textAlign: "left",
};

// About Us Section Styling
const AboutUsContainer = (isMobile) => ({
  display: "flex",
  flexDirection: isMobile ? "column" : "row",
  alignItems: "center",
  padding: isMobile ? "20px" : "50px", // Adjusted padding for mobile
  backgroundColor: colors.primary,
});

const AboutUsTitle = {
  fontSize: "1.8rem",
  fontWeight: 700,
  color: colors.darkText,
  marginBottom: "20px",
  textAlign: "left",
};

const Modal = ({ isOpen, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");
  const size = useWindowSize();
  const isMobile = size.width <= 768;

  if (!isOpen) {
    return null;
  }

  const validationSchema = Yup.object().shape({
    fullName: Yup.string().required("Full Name is required"),
    email: Yup.string().email("Invalid email format").required("Email is required"),
    phone: Yup.string().required("Phone Number is required"),
    gender: Yup.string().required("Gender is required"),
    occupation: Yup.string().required("Occupation is required"),
    location: Yup.string().required("Location is required"),
    qualification: Yup.string().required("Academic Qualification is required"),
    contactMethod: Yup.array()
      .min(1, "Select at least one contact method")
      .required("Preferred Contact Method is required"),
  });

  const initialValues = {
    fullName: "",
    email: "",
    phone: "",
    gender: "",
    occupation: "",
    contactMethod: [],
    location: "",
    qualification: "",
  };

  const handleSubmit = async (values) => {
    const today = new Date();
    const formattedDate = `${today.getMonth() + 1}/${today.getDate()}/${today.getFullYear()}`;

    setLoading(true);
    setStatusMessage("");
    try {
      values.date = formattedDate;
      await axios.post("https://sheetdb.io/api/v1/unq0jzik17gbo", values);
      alert("Registration successful!");
      onClose();
    } catch (error) {
      setStatusMessage("Failed to register. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const getModalContentStyle = (isMobile) => ({
    backgroundColor: "white",
    padding: "40px",
    borderRadius: "8px",
    maxWidth: "800px",
    width: isMobile ? "95%" : "90%",
  });

  const getFormContainerStyle = (isMobile) => ({
    display: "flex",
    flexDirection: isMobile ? "column" : "row",
    gap: "20px",
    flexWrap: "wrap",
  });

  return (
      // Use //@ts-ignore to ignore TypeScript error
  //@ts-ignore
    <div style={modalOverlay}>
      <div style={getModalContentStyle(isMobile)}>
        {/*// Use //@ts-ignore to ignore TypeScript error
  //@ts-ignore*/}
        <h2 style={modalTitle}>Register Your Interest</h2>
         {/*// Use //@ts-ignore to ignore TypeScript error
  //@ts-ignore*/}
        <p style={modalSubtitle}>
          Complete the form below to express your interest in the Travel Sales Consultancy Course.
        </p>

        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
          {() => (
        // Use //@ts-ignore to ignore TypeScript error
  //@ts-ignore
            <Form style={getFormContainerStyle(isMobile)}>
              <div style={formColumn}>
                <div style={formGroup}>
                  <label>Full Name:</label>
                  <Field type="text" name="fullName" style={inputStyle} />
                   {/*// Use //@ts-ignore to ignore TypeScript error
  //@ts-ignore*/}
                  <ErrorMessage name="fullName" component="div" style={errorText} />
                </div>
                <div style={formGroup}>
                  <label>Email Address:</label>
                  <Field type="email" name="email" style={inputStyle} />
                   {/*// Use //@ts-ignore to ignore TypeScript error
  //@ts-ignore*/}
                  <ErrorMessage name="email" component="div" style={errorText} />
                </div>
                <div style={formGroup}>
                  <label>Phone Number:</label>
                  <Field type="tel" name="phone" style={inputStyle} />
                   {/*// Use //@ts-ignore to ignore TypeScript error
  //@ts-ignore*/}
                  <ErrorMessage name="phone" component="div" style={errorText} />
                </div>
                <div style={formGroup}>
                  <label>Gender:</label>
                  <Field as="select" name="gender" style={inputStyle}>
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                  </Field>
                   {/*// Use //@ts-ignore to ignore TypeScript error
  //@ts-ignore*/}
                  <ErrorMessage name="gender" component="div" style={errorText} />
                </div>
                <div style={formGroup}>
                  <label>Current Occupation:</label>
                  <Field as="select" name="occupation" style={inputStyle}>
                    <option value="">Select Occupation</option>
                    <option value="student">Student</option>
                    <option value="unemployed">Unemployed Graduate</option>
                    <option value="professional">Working Professional</option>
                    <option value="other">Other</option>
                  </Field>
                   {/*// Use //@ts-ignore to ignore TypeScript error
  //@ts-ignore*/}
                  <ErrorMessage name="occupation" component="div" style={errorText} />
                </div>
              </div>

              <div style={formColumn}>
                <div style={formGroup}>
                  <label>Preferred Contact Method:</label>
                  <div style={checkboxContainer}>
                    <label>
                      <Field type="checkbox" name="contactMethod" value="email" /> Email
                    </label>
                    <label>
                      <Field type="checkbox" name="contactMethod" value="phone" /> Phone
                    </label>
                    <label>
                      <Field type="checkbox" name="contactMethod" value="whatsapp" /> WhatsApp
                    </label>
                  </div>
                   {/*// Use //@ts-ignore to ignore TypeScript error
  //@ts-ignore*/}
                  <ErrorMessage name="contactMethod" component="div" style={errorText} />
                </div>
                <div style={formGroup}>
                  <label>Your Location:</label>
                  <Field type="text" name="location" style={inputStyle} />
                   {/*// Use //@ts-ignore to ignore TypeScript error
  //@ts-ignore*/}
                  <ErrorMessage name="location" component="div" style={errorText} />
                </div>
                <div style={formGroup}>
                  <label>Highest Academic Qualification:</label>
                  <Field as="select" name="qualification" style={inputStyle}>
                    <option value="">Select Qualification</option>
                    <option value="masters">Masters</option>
                    <option value="firstDegree">First Degree</option>
                    <option value="hnd">HND</option>
                    <option value="diploma">Diploma</option>
                    <option value="shs">Senior High</option>
                  </Field>
                   {/*// Use //@ts-ignore to ignore TypeScript error
  //@ts-ignore*/}
                  <ErrorMessage name="qualification" component="div" style={errorText} />
                </div>
              </div>

              <div style={buttonContainer}>
                <button type="button" onClick={onClose} style={modalButton}>
                  Close
                </button>
                <button type="submit" style={modalButton} disabled={loading}>
                  {loading ? "Submitting..." : "Submit"}
                </button>
              </div>
               {/*// Use //@ts-ignore to ignore TypeScript error
  //@ts-ignore*/}
              {statusMessage && <p style={statusText}>{statusMessage}</p>}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

const modalOverlay = {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  zIndex: 1000,
};

const modalTitle = {
  fontSize: "1.8rem",
  fontWeight: 700,
  marginBottom: "10px",
  textAlign: "center",
};

const modalSubtitle = {
  fontSize: "1rem",
  color: "#555",
  marginBottom: "30px",
  textAlign: "center",
};

const formColumn = {
  flex: 1,
  display: "flex",
  flexDirection: "column" as const,
  gap: "15px",
};

const formGroup = {
  display: "flex",
  flexDirection: "column" as const,
  gap: "5px",
};

const inputStyle = {
  width: "100%",
  padding: "10px",
  borderRadius: "4px",
  border: "1px solid #ccc",
  marginTop: "5px",
};

const errorText = {
  color: "red",
  fontSize: "0.85rem",
};

const checkboxContainer = {
  display: "flex",
  flexDirection: "column" as const,
  gap: "5px",
  marginTop: "5px",
};

const buttonContainer = {
  display: "flex",
  justifyContent: "center",
  gap: "20px",
  marginTop: "20px",
  width: "100%",
};

const modalButton = {
  padding: "10px 20px",
  borderRadius: "20px",
  border: "none",
  cursor: "pointer",
  backgroundColor: "#000",
  color: "#fff",
};

const statusText = {
  textAlign: "center",
  marginTop: "20px",
  fontSize: "1rem",
};

const TwoColumnSection: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const size = useWindowSize();
  const isMobile = size.width <= 768;

  const getImageStyle = (isMobile) => ({
    width: isMobile ? "100%" : "80%",
    borderRadius: "8px",
  });

  return (
 //@ts-ignore to ignore TypeScript error
  //@ts-ignore
    <div style={getTwoColumnContainerStyle(isMobile)}>
         {/*// Use //@ts-ignore to ignore TypeScript error
  //@ts-ignore*/}
      <div style={getLeftColumnStyle(isMobile)}>
         {/*// Use //@ts-ignore to ignore TypeScript error
  //@ts-ignore*/}
        <h2 style={BenefitsTitle}>Benefits of Expressing Your Interest Early</h2>
         {/*// Use //@ts-ignore to ignore TypeScript error
  //@ts-ignore*/}
        <ul style={BenefitsList}>
          <li>
            <strong>Stay Updated</strong> – Be the first to know when registration opens and receive course
            updates.
          </li>
          <li>
            <strong>Exclusive Discount</strong> – Qualify for a special discount on the course fees.
          </li>
          <li>
            <strong>Priority Access</strong> – Secure a spot in this high-demand program before it fills up.
          </li>
        </ul>
        <Button label="Register Now" onClick={() => setIsModalOpen(true)} />
      </div>
      <div style={getRightColumnStyle(isMobile)}>
        <img src={hero_img} alt="Course Benefits" style={getImageStyle(isMobile)} />
      </div>
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </div>
  );
};

const TheAboutUsSection: React.FC = () => {
  const size = useWindowSize();
  const isMobile = size.width <= 768;
  return (
     //@ts-ignore to ignore TypeScript error
  //@ts-ignore
    <div style={AboutUsContainer(isMobile)}>
         {/*// Use //@ts-ignore to ignore TypeScript error
  //@ts-ignore*/}
      <div style={getLeftColumnStyle(isMobile)}>
         {/*// Use //@ts-ignore to ignore TypeScript error
  //@ts-ignore*/}
        <h2 style={AboutUsTitle}>About Marshalls University College and Adansi Travels</h2>

        <p style={{ paddingRight: "5%", paddingLeft: "5%", textAlign: "left" }}>
          Marshalls University College and Adansi Travels bring together academic excellence and industry
          expertise to prepare the next generation of travel sales consultants. With a curriculum designed by
          experts and practical training from industry leaders, this course provides a complete pathway into
          the travel industry.
        </p>

        <p style={{ paddingRight: "5%", paddingLeft: "5%", textAlign: "left" }}>
          <strong>Questions?</strong>
        </p>
        <p style={{ paddingRight: "5%", paddingLeft: "5%", textAlign: "left" }}>
          For any questions about the course, please email us at registrar@marshalls.edu.gh or call us at
          (+233) 27 774 1576
        </p>
      </div>
    </div>
  );
};

const GlobalStyle = `
  body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    background-color: ${colors.primary};
  }
  * {
    box-sizing: border-box;
  }
`;

const StyledButton = {
  backgroundColor: colors.button,
  color: "white",
  padding: "10px 20px",
  border: "none",
  borderRadius: "20px",
  cursor: "pointer",
  transition: "background 0.3s",
  marginTop: "20px",
};

const CTAButton = {
  backgroundColor: colors.button,
  color: "white",
  padding: "15px 30px",
  border: "none",
  borderRadius: "20px",
  cursor: "pointer",
  fontSize: "1.2rem",
  fontWeight: 700,
  marginTop: "30px",
  transition: "background 0.3s",
};

// Hero Section Styles
const getHeroContainerStyle = (isMobile) => ({
  display: "flex",
  flexDirection: isMobile ? "column" : "row",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "30px 20px",
  backgroundColor: colors.heroPrimary,
});

const getTextContainerStyle = (isMobile) => ({
  maxWidth: isMobile ? "100%" : "60%",
  textAlign: "left",
  marginTop: isMobile ? "20px" : "0",
});

const HeroTitle = {
  fontSize: "1.5rem",
  color: colors.darkText,
  fontWeight: 900,
  lineHeight: "1.2",
  marginBottom: "10px",
};

const HeroSubtitle = {
  fontSize: "1rem",
  color: colors.darkText,
  lineHeight: "1.4",
  textAlign: "left",
};

const HeroSection: React.FC<{ onOpenModal: () => void }> = ({ onOpenModal }) => {
  const size = useWindowSize();
  const isMobile = size.width <= 768;

  const getHeroImageStyle = (isMobile) => ({
    width: isMobile ? "100%" : "50%",
    borderRadius: "8px",
  });

  return (
     //@ts-ignore to ignore TypeScript error
  //@ts-ignore
    <div style={getHeroContainerStyle(isMobile)}>
      {isMobile ? (
        <>
          <img src={hero_img} alt="Be Among the First to Join Our Course!" style={getHeroImageStyle(isMobile)} />
           {/*// Use //@ts-ignore to ignore TypeScript error
  //@ts-ignore*/}
          <div style={getTextContainerStyle(isMobile)}>
            <h1 style={HeroTitle}>Launch Your Career in Travel Sales Consultancy</h1>
             {/*// Use //@ts-ignore to ignore TypeScript error
  //@ts-ignore*/}
            <p style={HeroSubtitle}>
              Prepare to enter the world of travel consultancy with industry-leading training from{" "}
              <strong>Marshalls University College</strong> and Adansi Travels.
            </p>
            <button
              style={StyledButton}
              onClick={() => {
                onOpenModal();
              }}
            >
              Get Started
            </button>
          </div>
        </>
      ) : (
        <>
         {/*// Use //@ts-ignore to ignore TypeScript error
  //@ts-ignore*/}
          <div style={getTextContainerStyle(isMobile)}>
            <h1 style={HeroTitle}>Launch Your Career in Travel Sales Consultancy</h1>
             {/*// Use //@ts-ignore to ignore TypeScript error
  //@ts-ignore*/}
            <p style={HeroSubtitle}>
              Prepare to enter the world of travel consultancy with industry-leading training from{" "}
              <strong>Marshalls University College</strong> and Adansi Travels.
            </p>
            <button
              style={StyledButton}
              onClick={() => {
                onOpenModal();
              }}
            >
              Get Started
            </button>
          </div>
          <img src={hero_img} alt="Be Among the First to Join Our Course!" style={getHeroImageStyle(isMobile)} />
        </>
      )}
    </div>
  );
};

// Button Component
interface ButtonProps {
  label: string;
  onClick: () => void;
}

const Button: React.FC<ButtonProps> = ({ label, onClick }) => (
  <button style={StyledButton} onClick={onClick}>
    {label}
  </button>
);

// Service Card Styling
const getCardStyle = (isMobile) => ({
  backgroundColor: colors.marshallRed,
  borderRadius: "10px",
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
  minWidth: isMobile ? "90%" : "300px", // Increased width on mobile
  margin: isMobile ? "10px" : "0 10px",
  overflow: "hidden",
  display: "flex",
  flexDirection: "column" as const,
  border: `5px solid ${colors.marshallRed}`,
  padding: "10px",
  textAlign: "left",
});

const ImageContainer = (isMobile) => ({
  width: "100%",
  height: isMobile ? "200px" : "180px", // Increased height on mobile
  overflow: "hidden",
});

const FullWidthImage = {
  width: "100%",
  height: "100%",
  objectFit: "cover" as const,
};

const CardContent = {
  padding: "10px",
};

const CardTitle = {
  fontSize: "16px",
  fontWeight: 700,
  color: "white",
  marginBottom: "8px",
};

const CardDescription = {
  color: "white",
  fontSize: "12px",
  lineHeight: "1.5",
  textAlign: "left",
  margin: "0",
};

const SliderContainer = (isMobile) => ({
  overflow: "hidden",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  backgroundColor: colors.marshallColor,
  padding: "30px 10px",
});

const SliderWrapper = {
  display: "flex",
  transition: "transform 1s ease",
};

const NavigationButton = (isMobile) => ({
  color: "white",
  border: "none",
  borderRadius: "50%",
  cursor: "pointer",
  fontSize: isMobile ? "2rem" : "3rem", // Reduced size on mobile
  padding: isMobile ? "10px" : "15px", // Reduced padding on mobile
  margin: "0 10px",
});

const ServiceSliderHeaderContainer = {
  width: "100%",
  backgroundColor: colors.marshallColor,
  padding: "40px",
  textAlign: "left",
  color: "white",
};

const ServiceSliderHeading = {
  fontSize: "1.2rem",
  fontWeight: 700,
  color: "white",
  marginBottom: "8px",
  width: "100%",
  textAlign: "left",
  lineHeight: "1.2",
};

const ServiceSliderSubheading = {
  fontSize: "1rem",
  color: "white",
  marginTop: "0",
  width: "100%",
  textAlign: "left",
};

const ServiceSliderTitle = "Essential Courses for Aspiring Travel Consultants";
const ServiceSliderDescription =
  "Build a solid foundation in travel consultancy with courses on customer service, sales strategies, destination knowledge, and essential industry skills.";

// Service Card Component
interface ServiceCardProps {
  icon: string;
  title: string;
  description: string;
}

const ServiceCard: React.FC<ServiceCardProps> = ({ icon, title, description }) => {
  const size = useWindowSize();
  const isMobile = size.width <= 768;

  return (
  //@ts-ignore to ignore TypeScript error
  //@ts-ignore
    <div style={getCardStyle(isMobile)}>
      <div style={ImageContainer(isMobile)}>
        <img src={icon} alt={`${title} icon`} style={FullWidthImage} />
      </div>
      <div style={CardContent}>
        <h3 style={CardTitle}>{title}</h3>
         {/*// Use //@ts-ignore to ignore TypeScript error
  //@ts-ignore*/}
        <p style={CardDescription}>{description}</p>
      </div>
    </div>
  );
};

const ServiceSection: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [direction, setDirection] = useState(1);
  const size = useWindowSize();
  const isMobile = size.width <= 768;

  const servicesData = [
    {
      icon: sales_img,
      title: "Customer-Centric Sales Techniques",
      description:
        "Master the art of selling travel services and creating customized travel experiences.",
    },
    {
      icon: tech_img,
      title: "Technology Proficiency",
      description:
        "Get hands-on training with GDS systems and AI tools essential in today’s travel industry.",
    },
    {
      icon: expertKnow,
      title: "Expert Knowledge in Tour Packaging",
      description: "Design and price travel packages to meet diverse client needs.",
    },
    {
      icon: visalegal_img,
      title: "Visa and Legal Insights",
      description: "Navigate visa requirements and provide accurate travel documentation support.",
    },
  ];

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => {
      if (prevIndex === servicesData.length - 1) {
        setDirection(-1);
        return prevIndex - 1;
      }
      return prevIndex + direction;
    });
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => {
      if (prevIndex === 0) {
        setDirection(1);
        return prevIndex + 1;
      }
      return prevIndex - direction;
    });
  };

  const sliderStyle = {
    ...SliderWrapper,
    transform: `translateX(-${currentIndex * 100}%)`,
  };

  return (
    <>
     {/*// Use //@ts-ignore to ignore TypeScript error
  //@ts-ignore*/}
      <div style={ServiceSliderHeaderContainer}>
         {/*// Use //@ts-ignore to ignore TypeScript error
  //@ts-ignore*/}
        <h2 style={ServiceSliderHeading}>{ServiceSliderTitle}</h2>
         {/*// Use //@ts-ignore to ignore TypeScript error
  //@ts-ignore*/}
        <p style={ServiceSliderSubheading}>{ServiceSliderDescription}</p>
      </div>

      <div style={SliderContainer(isMobile)}>
        <button style={NavigationButton(isMobile)} onClick={prevSlide}>
          {"<"}
        </button>
        <div style={{ width: "80%", overflow: "hidden" }}>
          <div style={sliderStyle}>
            {servicesData.map((service, index) => (
              <ServiceCard
                key={index}
                icon={service.icon}
                title={service.title}
                description={service.description}
              />
            ))}
          </div>
        </div>
        <button style={NavigationButton(isMobile)} onClick={nextSlide}>
          {">"}
        </button>
      </div>
    </>
  );
};

// Secondary Section Component
const SecondaryContainer = {
  display: "flex",
  flexDirection: "column" as const,
  alignItems: "center",
  padding: "50px 10px",
  backgroundColor: "white",
};

const getSecondaryTextContainerStyle = (isMobile) => ({
  maxWidth: isMobile ? "100%" : "90%",
  textAlign: "left",
  marginBottom: "10%",
  position: "relative",
  overflow: "hidden",
});

const SecondaryTitle = {
  fontSize: "2rem",
  color: colors.darkText,
  marginBottom: "10px",
  lineHeight: "1.5",
  fontWeight: 900,
  textAlign: "left",
};

const SecondarySubtitle = {
  fontSize: "1rem",
  color: colors.darkText,
  lineHeight: "1.9",
  textAlign: "left",
};

const gridItem = {
  display: "flex",
  flexDirection: "column" as const,
  alignItems: "center",
};

const iconsData = [
  { imageSrc: adansiLogo, text: "Adansi Travels" },
  { imageSrc: marshallLogo, text: "Marshalls University College" },
];

const imageStyle = {
  width: "70px",
  height: "70px",
  objectFit: "contain" as const,
  marginBottom: "5px",
};

const IconGrid: React.FC = () => {
  const size = useWindowSize();
  const isMobile = size.width <= 768;

  const getGridContainerStyle = (isMobile) => ({
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)", // Always two columns
    gap: "10px",
    padding: "10px",
    textAlign: "center" as const,
    alignItems: "center",
    justifyContent: "center",
    maxWidth: "400px",
    margin: "0 auto",
  });

  return (
    <div style={{ textAlign: "center", marginTop: "10%" }}>
      <p>
        <strong>Powered By</strong>
      </p>
      <div className="grid-container" style={getGridContainerStyle(isMobile)}>
        {iconsData.map((item, index) => (
          <div key={index} style={gridItem}>
            <img
              src={item.imageSrc}
              alt={item.text}
              style={{ ...imageStyle, width: isMobile ? "70px" : "70px" }}
            />
            <p>{item.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

const SecondarySectionWithServices: React.FC = () => {
  const size = useWindowSize();
  const isMobile = size.width <= 768;

  return (
    <div style={SecondaryContainer}>
         {/*// Use //@ts-ignore to ignore TypeScript error
  //@ts-ignore*/}
      <div style={getSecondaryTextContainerStyle(isMobile)}>
        <BgGlassmorphism />
         {/*// Use //@ts-ignore to ignore TypeScript error
  //@ts-ignore*/}
        <h2 style={SecondaryTitle}>Be Among the First to Join Our Course!</h2>
         {/*// Use //@ts-ignore to ignore TypeScript error
  //@ts-ignore*/}
        <p style={SecondarySubtitle}>
          Are you ready to embark on a career in travel consultancy? Our comprehensive Travel Sales
          Consultancy Course, crafted in partnership with industry experts, offers you everything you
          need to become a skilled travel professional. Enroll to gain essential skills in sales,
          marketing, customer service, tour packaging, and advanced technologies like GDS and AI.
        </p>
      </div>
      <ServiceSection />
    </div>
  );
};

const App: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  return (
    <>
      <style>{GlobalStyle}</style>
      <HeroSection onOpenModal={handleOpenModal} />
      <BgGlassmorphism />
      <SecondarySectionWithServices />
      <TwoColumnSection />
      <TheAboutUsSection />
      <IconGrid />
      <Modal isOpen={isModalOpen} onClose={handleCloseModal} />
    </>
  );
};
export default App;
