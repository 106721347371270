// BookingForm.jsx
import { useState, useEffect, FC } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useLocation } from "react-router-dom";
import Select from "react-select"; // Importing react-select
import * as Yup from "yup";
import {
  useSaveTourLeads,
  useCurrencyRate,
  useUpdateTourLeads,
  useValidateCouponCode,
} from "hooks/leads";
import Spinner from "components/Adways/ContentLoaders/formSpinner";
import { logEvent, getOrCreateUserId } from "../../../../src/google-analytics";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  Nationalities,
  formatAmount,
  formatTripName,
  getCurrencySymbol,
} from "hooks/Global";
import TermsAndConditionsComponent from "./TermsAndConditions";
//import { usePaystackPayment } from "react-paystack";
import { PaymentSuccessModal } from "containers/LandingPages/LandingPageForm/SuccessModelPage";
import logo from "../../../images/logo.png";
import { TransactionFailed } from "../../LandingPages/LandingPageSections/SiteError";
import PaystackHook from "components/Adways/Payment";
import { setPriority } from "os";
import CouponInput from "./GetCouponCode";
import PaymentSection from "./GetCouponCode";

const API_Keys = process.env.REACT_APP_PAYSTACK_API_KEY;

export interface CheckoutBookingProps {
  className?: string;
  children?: React.ReactNode;
  bookingData?: any;
  onClose?: () => void;
}

const CheckoutBooking: FC<CheckoutBookingProps> = ({
  className = "",
  children,
  bookingData,
  onClose,
}) => {
  const location = useLocation();

  const CurrencySymbol = getCurrencySymbol(bookingData.currencySymbol);

  useEffect(() => {
    const userId = getOrCreateUserId();
    logEvent("User", "Opened Booking Form", userId);
  }, []);

  //Modal page state
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [successModalText, setSuccessModalText] = useState("");
  const [accepted, setAccepted] = useState<boolean>(false);
  const [hasUpdated, setHasUpdated] = useState<boolean>(false);
  const [hasInitializePayment, setHasInitializePayment] = useState<boolean>(
    false
  );
  const [payNow, setPayNow] = useState<boolean>(false);
  const [transactionFailed, setTransactionFailed] = useState<boolean>(false);
  const handleAcceptChange = (accepted: boolean) => {
    setAccepted(accepted);
  };

  const [saveLeadsData, setSaveLeadsData] = useState([]);
  const [paystackConfig, setPaystackConfig] = useState({});
  const [updateLeadsData, setUpdateLeadsData] = useState([]);
  const [Email, setEmail] = useState<string>("");

  // State for validation message
  const [validationMessage, setValidationMessage] = useState({
    message: "",
    status: "false", // or "success"
  });
  const [DiscountedTotalAmount, setDiscountedTotalAmount] = useState(0);
  const [couponCode, setCouponCode] = useState<{ value?: string }>({});

  const { isLoading, data, submitted } = useSaveTourLeads(
    saveLeadsData,
    "https://workstation.adansitravels.com/api_save_tour_leads_4_website"
  );

  const { isLoadingUpdate, updateResponse, updated } = useUpdateTourLeads(
    updateLeadsData,
    "https://workstation.adansitravels.com/api_update_lead_with_payment"
  );

  //Get currenct rate
  const { currencyRate, isLoadingCurrencyRate } = useCurrencyRate();

  //Valid Coupon code
  const {
    couponCodeValidation,
    isValidatingCouponCode,
    couponCodeSubmitted,
  } = useValidateCouponCode(couponCode);
  const [Discount, setDiscount] = useState({
    discountType: "percentage",
    discountValue: 20,
  });

  //@ts-ignore
  const todaycurrencyRate =
    (!isLoadingCurrencyRate &&
      Object.keys(currencyRate).length > 0 &&
      currencyRate?.value) ||
    [];

  //Handle Payment
  let config = {
    reference: "",
    email: Email,
    amount: 0,
    currency: "GHS",
    publicKey: API_Keys,
  };

  let specialNeed = {
    hasSpecialNeed: "false",
    needDescription: "",
  };

  function calculatePayment(bookingData) {
    const { paymentPlan, paymentPlanValue, tourData } = bookingData;
    const totalAmount = tourData.totalCost;

    // Check if paymentPlanValue is zero, return totalAmount
    if (paymentPlanValue === "0") {
      return totalAmount;
    }

    // Check if paymentPlan is "Fixed Amount" and paymentPlanValue is not zero
    if (paymentPlan === "Fix Amount" && paymentPlanValue !== "0") {
      return parseFloat(paymentPlanValue); // Return the fixed amount as a number
    }

    // Check if paymentPlan is "Percentage" and calculate the amount
    if (paymentPlan === "Percentage") {
      const percentageValue = parseFloat(paymentPlanValue);
      return (percentageValue / 100) * totalAmount; // Calculate percentage of the totalAmount
    }

    // Return totalAmount by default if none of the conditions are met
    return totalAmount;
  }
  //**Coupon Code**//

  function applyDiscount(totalAmount: number, discount): number {
    let discountedTotal: number;

    if (discount.discountType === "percentage") {
      // Ensure the percentage value is between 0 and 100
      const percentageDiscount = Math.min(
        Math.max(discount.discountValue, 0),
        100
      );
      const discountAmount = (totalAmount * percentageDiscount) / 100;
      discountedTotal = totalAmount - discountAmount;
    } else if (discount.discountType === "value") {
      // Ensure the discount value does not exceed the total amount
      const discountAmount = Math.min(discount.discountValue, totalAmount);
      discountedTotal = totalAmount - discountAmount;
    } else {
      // Return the original totalAmount if discount type is not recognized
      discountedTotal = totalAmount;
    }

    // Ensure the final total is not less than zero
    return Math.max(discountedTotal, 0);
  }

  // Handler function for applying a coupon
  const useHandleApplyCoupon = async (coupon_code) => {
    setValidationMessage({ message: "", status: "error" }); // Reset validation message

    if (!coupon_code) {
      setValidationMessage({
        message: "Please enter a coupon code.",
        status: "error",
      });
      return;
    }
    //console.log("coupon code", coupon_code);
    setCouponCode({ value: coupon_code });
  };

  useEffect(() => {
    if (couponCodeSubmitted) {
      //NOTE: IMPLIMENTATION FOR COUPON CODE VALIDATION ISN'T AVAILABLE
      if (couponCodeValidation) {
        // console.log(
        //   " values",
        //   bookingData.tourData.totalCost,
        //   DiscountedTotalAmount
        // );
        setValidationMessage({
          message: `Coupon Code "${couponCode.value}" is not Valid.`,
          status: "error",
        });
        // console.log("total discount", DiscountedTotalAmount);

        //AVOID HITTING THE API
        // setDiscountedTotalAmount(
        //   applyDiscount(bookingData.tourData.totalCost, Discount)
        // );
        // console.log("total discount", DiscountedTotalAmount);
      } else {
        setValidationMessage({
          message: "Invalid Coupon Code",
          status: "error",
        });
      }
    }
  }, [couponCodeSubmitted]);

  const depositAmount = calculatePayment(bookingData);

  //Handle model after successfull login
  useEffect(() => {
    if (!hasUpdated) {
      setHasUpdated(true);
      return;
    }

    if (updated) {
      setSuccessModalText(
        `Your payment has been received, and your ${formatTripName(
          bookingData.tourData.tourName
        )} tour has been successfully booked. Our sales team will contact you shortly.`
      );
      setIsModalOpen(true);
      //console.log("email update response", updateResponse);
    }
  }, [updated]);

  const tryAgain = () => {
    setHasInitializePayment(true);
  };
  const closeTransaction = () => {
    setTransactionFailed(false);
    setSuccessModalText(
      `Your ${formatTripName(
        bookingData.tourData.tourName
      )} tour has been successfully booked. Our sales team will contact you shortly.`
    );
    setIsModalOpen(true);
  };

  const onSuccessPaystack = (reference) => {
    // Pass reference to hook after success call.

    setHasInitializePayment(false);

    setUpdateLeadsData({
      //@ts-ignore
      leadid: data.leadid,
      meta: {
        reference: reference,
      },
    });
  };

  const onClosePaystack = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    //setTransactionFailed(true);

    setTransactionFailed(true);
    setHasInitializePayment(false);

    //console.log("closed");
  };

  if (submitted && payNow) {
    //@ts-ignore
    //console.log("booking reference: ", data.leadid);

    //@ts-ignore
    config.reference = String(data.leadid);
    config.amount = depositAmount * todaycurrencyRate * 100;
    //@ts-ignore
    config.email = Email;
    console.log(config);

    setPaystackConfig(config);
    setHasInitializePayment(true);
    setPayNow(false);
  }

  //Handles form search
  const NationalitySelect = ({ options, field, form }) => (
    <Select
      options={options}
      name={field.name}
      value={
        options ? options.find((option) => option.value === field.value) : ""
      }
      onChange={(option) => form.setFieldValue(field.name, option.value)}
      onBlur={field.onBlur}
      isSearchable={true}
      className="react-select-container"
      classNamePrefix="react-select"
    />
  );

  const nationalities = Nationalities();

  const nationalityOptions = nationalities.map((nationality) => ({
    value: nationality.name,
    label: nationality.name.charAt(0).toUpperCase() + nationality.name.slice(1),
  }));

  const initialValues = {
    tourId: bookingData.tourData.tourId,
    tourName: bookingData.tourData.tourName,
    specialNeed: specialNeed,
    surname: "",
    othernames: "",
    nationality: "",
    placeOfWork: "",
    phone: "",
    email: "",
    emergencyName: "",
    emergencyPhone: "",
    PaxCount: bookingData.paxCounter,
    totalCost: bookingData.tourData.totalCost,
    costing: bookingData.costing,
    source: "website",
    currentCurrencyRate: todaycurrencyRate,
  };

  const validationSchema = Yup.object().shape({
    surname: Yup.string().required("Required"),
    othernames: Yup.string().required("Required"),
    nationality: Yup.string().required("Required"),
    placeOfWork: Yup.string().required("Required"),
    phone: Yup.string()
      .required("Required")
      .matches(/^[0-9]+$/, "Invalid phone number"),

    email: Yup.string().required("Required").email("Invalid email address"),
    confirmEmail: Yup.string()
      .oneOf([Yup.ref("email"), null], "Emails must match")
      .required("Required"),

    specialNeed: Yup.object().shape({
      hasSpecialNeed: Yup.string().required("Required"),
      needDescription: Yup.string().when(
        "hasSpecialNeed",
        ([hasSpecialNeed], schema) => {
          return hasSpecialNeed === "true"
            ? schema.required("Please describe the special need")
            : schema.notRequired();
        }
      ),
    }),

    emergencyName: Yup.string().required("Required"),
    emergencyPhone: Yup.string()
      .required("Required")
      .matches(/^[0-9]+$/, "Invalid phone number"),
  });

  if (isLoading || isLoadingUpdate) {
    return (
      <Spinner className="w-[200px] h-[100px] sm:w-[800px] sm: min-h-screen" />
    );
  }

  return (
    <div className=" mx-auto width-full bg-gray-100 p-2 md:p-10">
      <div className="justify-content-center mx-auto row">
        <div className="col-12 col-md-8">
          <h3 className="text-center text-xl md:text-2xl p-1 text-gray-600 mt-0 font-semibold">
            BOOKING FORM
          </h3>
          <p className="text-center p-0 text-base md:text-lg text-gray-600 font-semibold"></p>
          <div className="text-left card">
            <div className="card-body">
              <div className="alert alert-primary text-base md:text-base mt-2 text-left md:text-center p-2 text-gray-600 mb-8">
                Thank you for booking our{" "}
                {formatTripName(bookingData.tourData.tourName)} tour package!
                Please complete the form below to finish your booking.
              </div>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={async (values, { setSubmitting }) => {
                  let result = false;
                  try {
                    // Remove selectedStar and selectedDate if present
                    //const sanitizedValues = updateFormValues(values);
                    // console.log("sanitizedValues: ", sanitizedValues);

                    setSaveLeadsData([values]);
                    setEmail(values.email);
                    //updateEmail(sanitizedValues.email);
                    // Handle successful submission (e.g., show success message, redirect, etc.)
                  } catch (error) {
                    console.error("Form submission error: ", error);
                    // Handle submission error (e.g., show error message)
                  } finally {
                    // console.log("submited status", submitted);
                    if (submitted && !payNow) {
                      setSubmitting(false);
                      setIsModalOpen(true);
                    }
                  }
                }}
              >
                {({ isSubmitting, setFieldValue, values }) => (
                  <Form className="grid grid-cols-1 md:grid-cols-2 gap-5">
                    <div className="text-left col-span-1 md:col-span-2 text-xl font-semibold">
                      Personal Information
                    </div>
                    <div className="mb-1 sm:ml-3">
                      <label className="form-label font-normal text-black">
                        Surname<span style={{ color: "red" }}>*</span>
                      </label>
                      <Field
                        type="text"
                        name="surname"
                        className="form-control rounded-none"
                      />
                      <ErrorMessage
                        name="surname"
                        component="div"
                        className="text-red-600 text-sm font-light"
                      />
                      <p className="text-muted text-sm font-light">
                        As seen in passport
                      </p>
                    </div>
                    <div className="mb-1 sm:ml-3">
                      <label className="form-label font-normal text-black">
                        Other Names<span style={{ color: "red" }}>*</span>
                      </label>
                      <Field
                        type="text"
                        name="othernames"
                        className="form-control rounded-none"
                      />
                      <ErrorMessage
                        name="othernames"
                        component="div"
                        className="text-red-600 text-sm font-light"
                      />
                      <p className="text-muted text-sm font-light">
                        As seen in passport
                      </p>
                    </div>

                    <div className="mb-1 sm:ml-3">
                      <label className="form-label font-normal text-black">
                        Nationality<span style={{ color: "red" }}>*</span>
                      </label>
                      <Field
                        name="nationality"
                        component={NationalitySelect}
                        options={nationalityOptions}
                        className="border-none"
                      />
                      <ErrorMessage
                        name="nationality"
                        component="div"
                        className="text-red-600 text-sm font-light"
                      />
                    </div>

                    <div className="mb-1 sm:ml-3">
                      <div className="mb-1">
                        <label className="form-label font-normal text-black">
                          Phone Number<span className="text-red-500">*</span>
                        </label>
                        <PhoneInput
                          country={"gh"}
                          value={values.phone}
                          onChange={(phone) => {
                            // Parse the phone number
                            setFieldValue("phone", phone);
                          }}
                          inputStyle={{ width: "100%" }}
                        />
                        <ErrorMessage
                          name="phone"
                          component="div"
                          className="text-red-600 text-sm font-light"
                        />
                      </div>
                    </div>
                    <div className="mb-1 sm:ml-3">
                      <label className="form-label font-normal text-black">
                        Email<span style={{ color: "red" }}>*</span>
                      </label>
                      <Field
                        type="text"
                        name="email"
                        className="form-control rounded-none"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="text-red-600 text-sm font-light"
                      />
                    </div>
                    <div className="mb-1 sm:ml-3">
                      <label className="form-label font-normal text-black">
                        Confirm Email<span style={{ color: "red" }}>*</span>
                      </label>
                      <Field
                        type="text"
                        name="confirmEmail"
                        className="form-control rounded-none"
                      />
                      <ErrorMessage
                        name="confirmEmail"
                        component="div"
                        className="text-red-600 text-sm font-light"
                      />
                    </div>

                    <div className="mb-1 sm:ml-3">
                      <label className="form-label font-normal text-black">
                        Emergency Contact Person Name
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <Field
                        type="text"
                        name="emergencyName"
                        className="form-control rounded-none"
                      />
                      <ErrorMessage
                        name="emergencyName"
                        component="div"
                        className="text-red-600 text-sm font-light"
                      />
                    </div>

                    <div className="mb-1 sm:ml-3">
                      <label className="form-label font-normal text-black">
                        Emergency Contact Person Phone
                        <span className="text-red-500">*</span>
                      </label>
                      <PhoneInput
                        country={"gh"}
                        value={values.emergencyPhone}
                        onChange={(emergencyPhone) => {
                          // Parse the phone number
                          setFieldValue("emergencyPhone", emergencyPhone);
                        }}
                        inputStyle={{ width: "100%" }}
                      />
                      <ErrorMessage
                        name="emergencyPhone"
                        component="div"
                        className="text-red-600 text-sm font-light"
                      />
                    </div>
                    <div className="mb-1 sm:ml-3">
                      <label className="form-label font-normal text-black">
                        Place of Work<span style={{ color: "red" }}>*</span>
                      </label>
                      <Field
                        type="text"
                        name="placeOfWork"
                        className="form-control rounded-none"
                      />
                      <ErrorMessage
                        name="placeOfWork"
                        component="div"
                        className="text-red-600 text-sm font-light"
                      />
                    </div>

                    <div className="mb-1 sm:ml-3">
                      <label className="form-label font-normal text-black">
                        Any Special Need?<span style={{ color: "red" }}>*</span>
                      </label>
                      <Field
                        as="select"
                        name="specialNeed.hasSpecialNeed"
                        className="form-control rounded-none"
                      >
                        <option value={"false"}>No</option>
                        <option value={"true"}>Yes</option>
                      </Field>
                      <ErrorMessage
                        name="specialNeed.hasSpecialNeed"
                        component="div"
                        className="text-red-600 text-sm font-light"
                      />
                    </div>

                    {values.specialNeed.hasSpecialNeed === "true" && (
                      <div className="mb-1 sm:ml-3">
                        <label className="form-label font-normal text-black">
                          Description of Special Need
                        </label>
                        <Field
                          type="text"
                          name="specialNeed.needDescription"
                          className="form-control rounded-none"
                          placeholder="Please describe your special need"
                        />
                        <ErrorMessage
                          name="specialNeed.needDescription"
                          component="div"
                          className="text-red-600 text-sm font-light"
                        />
                      </div>
                    )}

                    <div className="col-span-1 md:col-span-2 text-center mt-4">
                      <div className="flex flex-col  bg-white rounded-lg shadow-lg">
                        <div className="flex flex-col md:flex-row">
                          {/* Transaction Information Table */}
                          <div className="md:w-3/5 md:p-4 text-left p-3 rounded-l-lg shadow-r-lg">
                            <h2 className="text-2xl font-semibold mb-4">
                              Transaction Information
                            </h2>
                            {isLoadingCurrencyRate || isValidatingCouponCode ? (
                              <Spinner className="w-[50px] h-[50px] mx-auto" />
                            ) : (
                              <div className="overflow-x-auto">
                                <table className="w-full text-left text-base">
                                  <tbody>
                                    {/* Total Cost Row */}
                                    <tr className="font-normal md:font-medium text-base">
                                      <td className="py-3 ">Total Cost:</td>
                                      <td className="py-3">
                                        {DiscountedTotalAmount === 0 ? (
                                          <span className=" font-semibold text-gray-700">
                                            {CurrencySymbol}{" "}
                                            {formatAmount(
                                              bookingData.tourData.totalCost
                                            )}
                                          </span>
                                        ) : (
                                          <div>
                                            <span className=" text-red-500 line-through">
                                              {CurrencySymbol}{" "}
                                              {formatAmount(
                                                bookingData.tourData.totalCost
                                              )}
                                            </span>
                                            <span className="text-black">
                                              {" "}
                                              <br/>{" "}
                                            </span>
                                            <span className="pl-2 font-semibold text-green-700">
                                              {CurrencySymbol}{" "}
                                              {formatAmount(
                                                DiscountedTotalAmount
                                              )}
                                            </span>
                                          </div>
                                        )}
                                      </td>
                                    </tr>

                                    {/* Currency Rate Row */}
                                    {bookingData.currencySymbol === "USD" && (
                                      <tr className="md:font-medium font-normal ">
                                        <td className="py-3">
                                          USD Exchange Rate:
                                        </td>
                                        <td className="py-3 font-semibold text-gray-700">
                                          {todaycurrencyRate}
                                        </td>
                                      </tr>
                                    )}

                                    {/* Deposit Amount (USD) Row */}
                                    {bookingData.currencySymbol === "USD" && (
                                      <tr className="py-3 font-normal sm:font-medium">
                                        <td className="py-3 ">
                                          Initial Deposit (USD):
                                        </td>
                                        <td className="py-3 text-gray-700">
                                          {bookingData.tourData.totalCost ===
                                            depositAmount &&
                                          DiscountedTotalAmount === 0 ? (
                                            <span className="font-semibold text-gray-700">
                                              {CurrencySymbol}{" "}
                                              {formatAmount(depositAmount)}
                                            </span>
                                          ) : (
                                            <div>
                                              <span className="pl-2 font-semibold text-gray-700">
                                                {CurrencySymbol}{" "}
                                                {formatAmount(
                                                  DiscountedTotalAmount
                                                )}
                                              </span>
                                            </div>
                                          )}
                                        </td>
                                      </tr>
                                    )}

                                    {/* Deposit Amount (GHS) Row */}
                                    {bookingData.currencySymbol === "USD" && (
                                      <tr className="py-3 font-normal md:font-medium">
                                        <td className="py-3 ">
                                          Initial Deposit (GHS):
                                        </td>
                                        {bookingData.tourData.totalCost ===
                                          depositAmount &&
                                        DiscountedTotalAmount === 0 ? (
                                          <td className="py-3 font-semibold">
                                            ₵{" "}
                                            {formatAmount(
                                              depositAmount * currencyRate.value
                                            )}
                                          </td>
                                        ) : (
                                          <td className="py-3 font-semibold">
                                            ₵{" "}
                                            {formatAmount(
                                              DiscountedTotalAmount *
                                                todaycurrencyRate
                                            )}
                                          </td>
                                        )}
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            )}
                          </div>

                          {/* Coupon Code Section */}
                          <div className="md:w-3/5 text-left p-6 pb-3 rounded-r-lg shadow-r-xl">
                            <h2 className="pt-0 md:pt-10 text-lg font-base">
                              Have a coupon code?
                            </h2>
                            {/* Display validation message with conditional styling */}
                            <p
                              className={`font-normal text-base mt-2 ${
                                validationMessage.status === "success"
                                  ? "text-green-700  bg-green-100 p-2"
                                  : "text-red-500"
                              }`}
                            >
                              {validationMessage.message}
                              {validationMessage.status === "success" && (
                                <span>
                                  <span className="text-gray-900">{` you've earned `}</span>
                                  <span className="font-semibold text-gray-900">{` $${formatAmount(
                                    bookingData.tourData.totalCost -
                                      DiscountedTotalAmount
                                  )}`}</span>
                                  <span className="text-gray-900">{` with Coupon Code ${couponCode.value}`}</span>
                                </span>
                              )}
                            </p>
                            <div className="mt-4">
                              <PaymentSection
                                onApplyCoupon={useHandleApplyCoupon}
                              />
                            </div>
                          </div>
                        </div>
                        {/* Deposit Requirement Message */}
                        <div className="mb-10 mx-auto justify-center p-4 text-left bg-red-100 text-red-600 rounded-lg">
                          <span>An Initial Deposit of</span>
                          {bookingData.tourData.totalCost === depositAmount &&
                          DiscountedTotalAmount === 0 ? (

                            <span className="px-2 font-semibold">
                              ₵{" "}
                              {formatAmount(depositAmount * currencyRate?.value)}
                            </span>
                          ) : (
                            <span className="px-2 font-semibold">
                              ₵{" "}
                              {formatAmount(
                                DiscountedTotalAmount * currencyRate?.value
                              )}
                            </span>
                          )}
                          is required to proceed with this payment.
                        </div>
                      </div>
                    </div>

                    <div className="col-span-1 md:col-span-2 text-center">
                      <TermsAndConditionsComponent
                        submit={submitted}
                        onSubmitted={() => {}}
                        accepted={accepted}
                        onAccepted={handleAcceptChange}
                      />
                    </div>
                    <div className="col-span-1 md:col-span-2 text-center mt-4 mb-20">
                      <div className="flex md:mx-20 pb-10 justify-between md:space-x-10">
                        <button
                          type="submit"
                          disabled={!accepted}
                          className={`mt-4 px-4 py-2 rounded ${
                            accepted
                              ? "bg-green-600 text-white hover:bg-green-700"
                              : "bg-gray-300 text-gray-500 cursor-not-allowed"
                          }`}
                          onClick={() => {
                            setPayNow(true);
                          }}
                        >
                          Pay Now
                        </button>

                        <button
                          type="submit"
                          className={`mt-4 px-4 py-2 rounded ${
                            accepted
                              ? "bg-yellow-600 text-white hover:bg-yellow-700"
                              : "bg-gray-300 text-gray-500 cursor-not-allowed"
                          }`}
                          disabled={!accepted}
                          onClick={() => {
                            setPayNow(false);
                          }}
                        >
                          Pay Later
                        </button>
                        <button
                          type="button"
                          className={`mt-4 px-4 py-2 rounded bg-red-500 text-white hover:bg-red-700"`}
                          onClick={onClose}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>

      <PaymentSuccessModal
        isOpen={isModalOpen}
        fbPixelTriggered={false}
        onClose={() => setIsModalOpen(false)}
        onfbPixelTriggered={() => {}}
        msgText={successModalText}
      />
      {hasInitializePayment && (
        <PaystackHook
          config={paystackConfig}
          onClose={onClosePaystack}
          onSuccess={onSuccessPaystack}
        />
      )}
      {
        <TransactionFailed
          errorMessage=""
          details={formatTripName(bookingData.tourData.tourName)}
          buttonText="Try Again"
          isOpen={transactionFailed}
          onClose={() => {}}
          onRetryTransaction={tryAgain}
          onCancelTransaction={closeTransaction}
        />
      }
    </div>
  );
};

export default CheckoutBooking;
