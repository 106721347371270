import React, { FC } from "react";
import { Link, useNavigate } from "react-router-dom"; // Import Link from react-router-dom
import GallerySlider from "components/GallerySlider/GallerySlider";
import Badge from "../../shared/Badge/Badge";
import { getCurrencySymbol, formatAmount, formatFBdate, formatTripName } from "hooks/Global/index";
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedTour  } from "../../redux/slices/globalConfigReducer";
import SoldOut from "../Adways/SoldOut";

export interface TrendingToursProps {
  className?: string;
  data: {
    images: string,
    id: string,
    name: string,
    days: number,
    startsFrom: number,
    destination: string,
    startsFromCurrency: string,
    checkIn: Date,
    soldout:Boolean
  };
}


const TrendingTours: FC<TrendingToursProps> = ({ className = "", data }) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const saveAndRedirect = (data, url) => {
    dispatch(setSelectedTour(data));
    navigate(url);
  };

  const handleClick = (e) => {
    e.preventDefault(); // Prevent default behavior of the Link
    saveAndRedirect(data, `/tour-detail/${encodeURIComponent(data.name)}`);
  };

  const saleOff = true;

  const renderSliderGallery = () => (
    <div className="flex-shrink-0 p-3 w-full sm:w-64">
      <GallerySlider
        ratioClass="aspect-w-1 aspect-h-1"
        galleryImgs={data.images}
        className="w-full h-full rounded-2xl overflow-hidden"
        uniqueID={`PropertyCardH_${data.id}`}
        //href={`/tour-detail/${data.name}`}
      />
    </div>
  );


  

  const renderContent = () => (
    <div className="flex-grow p-3 sm:pr-6 flex flex-col items-start">
      <div className="space-y-4 w-full">
        <div className="inline-flex space-x-3">
          <Badge name={<div className="flex items-center"><i className="text-sm las la-globe"></i><span className="ml-1">{data.destination}</span></div>} />
          <Badge name={<div className="flex items-center"><i className="text-sm las la-cloud-moon"></i><span className="ml-1">Duration : {data.days} Days</span></div>} color="yellow" />
        </div>
        <div className="flex items-center space-x-2">
          <h2 className="text-lg font-medium capitalize">
            <span className="line-clamp-2">{formatTripName(data.name)}</span>
          </h2>
        </div>
        <div className="w-14 border-b border-neutral-200/80 dark:border-neutral-700 "></div>
        <div className="flex w-full justify-between items-end">
          <div className="flex items-center">
            <i className="text-sm las la-calendar"></i>
            <span className="ml-1">{formatFBdate(data.checkIn)}</span>
          </div>
          <span className="flex items-center justify-center px-2.5 py-1.5 border-2 border-secondary-500 rounded-lg leading-none text-sm font-medium text-secondary-500">
            {getCurrencySymbol(data.startsFromCurrency)}{formatAmount(data.startsFrom)}
          </span>
        </div>
      </div>
    </div>
  );

  return (
    <Link
    to="#"
    onClick={(e) => {
      e.preventDefault();
      handleClick(e);
    }}
      className={`nc-PropertyCardH group relative bg-white dark:bg-neutral-900 border border-neutral-200/80 dark:border-neutral-700 rounded-3xl overflow-hidden ${className}`}
    >
      <div className="h-full w-full flex flex-col sm:flex-row sm:items-center">
        {renderSliderGallery()}
        {renderContent()}
      </div>
      <SoldOut
        colorClass={` bg-neutral-100 dark:bg-neutral-700 hover:bg-neutral-200 hover:bg-opacity-70 text-neutral-6000 dark:text-neutral-400`}
         // Use //@ts-ignore to ignore TypeScript error
  //@ts-ignore
        isLiked={data?.soldout === "true"}
        className="absolute right-5 top-5 sm:right-3 sm:top-3 "
      />
    </Link>
  );
};

export default TrendingTours;
