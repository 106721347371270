// NextYearTourSlotReserveForm.jsx
import { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Select from "react-select";
import * as Yup from "yup";
import { useSaveTourLeads } from "hooks/leads";
import { SuccessModal } from "../LandingPages/LandingPageForm/SuccessModelPage";
import Spinner from "components/Adways/ContentLoaders/formSpinner";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { SiteErrorModal } from "../LandingPages/LandingPageSections/SiteError";
import ImageSlider from "./calendarSlider";
import img20241 from "../../images/slider-img/img1.jpg";
import img20242 from "../../images/slider-img/img2.jpg";
import img20243 from "../../images/slider-img/img3.jpg";
import img20244 from "../../images/slider-img/img4.jpg";
import img20245 from "../../images/slider-img/img5.jpg";
import img20246 from "../../images/slider-img/img6.jpg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const NextYearTourSlotReserveForm = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fbPixelTriggered, setfbPixelTriggered] = useState(false);
  const [saveLeadsData, setSaveLeadsData] = useState({});
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [customDestination, setCustomDestination] = useState("");

  const { isLoading, submitted, data, isError, error } = useSaveTourLeads(
    saveLeadsData,
    "https://workstation.adansitravels.com/website_campaign_spot_request_forms"
  );

  const closeFormModal = () => setIsErrorModalOpen(false);

  const allowedOptions = [
    "website",
    "facebook",
    "instagram",
    "twitter",
    "linkedIn",
    "youTube",
    "whatsApp",
    "staff",
    "flyer",
    "tv",
    "radio",
  ];

  const destinationOptions = [
    { value: "Europe", label: "Europe" },
    { value: "Canada", label: "Canada" },
    { value: "UK", label: "UK" },
    { value: "USA", label: "USA" },
    { value: "EuropeCruise", label: "Europe Cruise (Italy, Croatia, Greece)" },
    { value: "ArabianCruise", label: "Arabian Cruise" },
    { value: "SingaporeCruise", label: "Singapore Cruise" },
    { value: "Barbados", label: "Barbados" },
    { value: "Dubai", label: "Dubai" },
    { value: "SouthAfrica", label: "South Africa" },
    { value: "EgyptAndMorocco", label: "Egypt and Morocco" },
    { value: "Other", label: "Other" },
  ];

  useEffect(() => {
    if (submitted && data) {
      setIsModalOpen(true);
   // window.location.href = "https://drive.google.com/drive/folders/1DKDkfoI-5PMzRhg9x1nu9lv9-6CO-VRw?usp=sharing";
    }
  }, [submitted, data]);

  useEffect(() => {
    if (isError) {
      setIsErrorModalOpen(true);
    }
  }, [isError]);

  const initialValues = {
    firstName: "",
    otherNames: "",
    phone: "",
    email: "",
    destination: "",
    source: "",
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("Required"),
    otherNames: Yup.string().required("Required"),
    phone: Yup.string()
      .required("Required")
      .matches(/^[0-9]+$/, "Invalid phone number"),
    email: Yup.string().required("Required").email("Invalid email address"),
    destination: Yup.string().required("Required"),
    source: Yup.string().required("Required"),
  });

  const handleFormSubmission = async (values, { setSubmitting }) => {
    try {
      // Assign custom destination if provided
      if (values.destination === "Other" && customDestination) {
        values.destination = customDestination;
      }
      console.log("Form values: ", values);
      setSaveLeadsData(values);
    } catch (error) {
      console.error("Form submission error: ", error);
      setIsErrorModalOpen(true);
    } finally {
      setSubmitting(false);
    }
  };

  if (isLoading) {
    return <Spinner className="min-h-screen" />;
  }

  // Define your images. Ensure these paths are correct and images are placed in the public/images directory.
  const images = [img20241, img20242, img20243, img20244, img20245];
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: true,
    autoplaySpeed: 3000,
    adaptiveHeight: true,
    arrows: true,
  };

  return (
    <div className="flexs w-full bg-green-50">
      <div className="min-w-full">
        <Slider {...settings}>
          {images.map((src, index) => (
            <div key={index}>
              <img
                src={src}
                alt={`Slide ${index + 2}`}
                className="w-full bg-transparent bg-black h-[600px] object-cover rounded-none md:rounded"
              />
            </div>
          ))}
        </Slider>
        <h2 className="text-lg text-white -mt-2 w-[200px] z-20 p-2 bg-green-700 font-semibold mb-4 rounded-ee-xl">
          Recap of 2024
        </h2>
      </div>
      {/* <h3 className="text-center text-3xl md:text-3xl p-2 text-gray-900 mt-0 font-semibold">
            2025 Tour Calendar
          </h3> */}
      <div className="  md:w-[950px] mx-auto md:pl-8 mt-10 md:mt-0">
        <h2 className="text-2xl -mt-4 md:text-4xl text-green-700 text-center pb-8 font-semibold">
          Feel More, See More
        </h2>

        <p className="mb-6 text-base md:text-lg p-3">
          <strong>2024 was an incredible year!</strong> We delivered
          unforgettable travel experiences that resonated with your unique
          vibes. As we look forward to 2025, we're thrilled to introduce new
          tours designed to help you <strong>See More</strong>{" "}
          <strong>Feel More</strong>.
          <br />
          
        </p>
      </div>
      <div className="container mx-auto px-0 md:px-2 m-0 justify-center items-center md:w-8xl lg:w-2/3 pb-20  p-1 ">
        <div className="justify-content-center mx-auto">
          <div className="col-12">
            {/* <ImageSlider images={images} /> */}
            {/* Insert ImageSlider component here */}
            <div className="text-left bg-white mt-2 w-11/12 md:w-full mx-auto p-2 md:rounded-xl ">
              <div className="card-body md:p-10 ">
                {/* <div className="alert w-full alert-primary text-base md:text-base mt-2 text-left md:text-center p-2 text-gray-600 mb-8">
                Thank you for your interest in our tour packages! Please
                complete the form below to request our 2025 tour calendar.
              </div> */}
               <p className="mb-6 text-base text-left">
              Complete the form below to reserve your spot and embark on another
              year of amazing adventures with Adansi Travels!
               </p>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleFormSubmission}
                >
                  {({ isSubmitting, setFieldValue, values }) => (
                    <Form className="">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-5">

                      <div className="mb-4">
                        <label className="form-label font-normal text-black">
                          First Name<span className="text-red-500">*</span>
                        </label>
                        <Field
                          type="text"
                          name="firstName"
                          className="form-control rounded border px-3 py-2 w-full"
                        />
                        <ErrorMessage
                          name="firstName"
                          component="div"
                          className="text-red-600 text-sm font-light"
                        />
                      </div>
                      <div className="mb-4">
                        <label className="form-label font-normal text-black">
                          Other Names<span className="text-red-500">*</span>
                        </label>
                        <Field
                          type="text"
                          name="otherNames"
                          className="form-control rounded border px-3 py-2 w-full"
                        />
                        <ErrorMessage
                          name="otherNames"
                          component="div"
                          className="text-red-600 text-sm font-light"
                        />
                      </div>
                      <div className="mb-4">
                        <label className="form-label font-normal text-black">
                          Phone Number<span className="text-red-500">*</span>
                        </label>
                        <PhoneInput
                          country={"gh"}
                          value={values.phone}
                          onChange={(phone) => setFieldValue("phone", phone)}
                          inputStyle={{ width: "100%" }}
                          containerClass="w-full"
                        />
                        <ErrorMessage
                          name="phone"
                          component="div"
                          className="text-red-600 text-sm font-light"
                        />
                      </div>
                      <div className="mb-4">
                        <label className="form-label font-normal text-black">
                          Email<span className="text-red-500">*</span>
                        </label>
                        <Field
                          type="email"
                          name="email"
                          className="form-control rounded border px-3 py-2 w-full"
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="text-red-600 text-sm font-light"
                        />
                      </div>
                      <div className="mb-4">
                        <label className="form-label font-normal text-black">
                          Destination<span className="text-red-500">*</span>
                        </label>
                        <Select
                          name="destination"
                          options={destinationOptions}
                          onChange={(option) => {
                            setFieldValue("destination", option.value);
                            setCustomDestination(
                              option.value === "Other" ? "" : null
                            );
                          }}
                          className="react-select-container"
                          classNamePrefix="react-select"
                          placeholder="Select Destination"
                        />
                        <ErrorMessage
                          name="destination"
                          component="div"
                          className="text-red-600 text-sm font-light"
                        />
                      </div>
                      {values.destination === "Other" && (
                        <div className="mb-4">
                          <label className="form-label font-normal text-black">
                            Have a different destination in mind? Share with us
                          </label>
                          <Field
                            type="text"
                            name="customDestination"
                            className="form-control rounded border px-3 py-2 w-full"
                            value={customDestination}
                            onChange={(e) =>
                              setCustomDestination(e.target.value)
                            }
                          />
                        </div>
                      )}
                      <div className="mb-4">
                        <label className="form-label font-normal text-black">
                          Source<span className="text-red-500">*</span>
                        </label>
                        <Field
                          as="select"
                          name="source"
                          className="form-control rounded border px-3 py-2 w-full"
                        >
                          <option value="">Select an option</option>
                          {allowedOptions.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="source"
                          component="div"
                          className="text-red-600 text-sm font-light"
                        />
                      </div>
                      </div>
                      <div className=" w-full text-center justify-center mt-8">
                        <button
                          type="submit"
                          className="btn bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-6 rounded w-full md:w-auto"
                          disabled={isSubmitting}
                        >
                          Submit
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Success Modal */}
      <SuccessModal
        isOpen={isModalOpen}
        fbPixelTriggered={fbPixelTriggered}
        onClose={() => setIsModalOpen(false)}
        onfbPixelTriggered={() => setfbPixelTriggered(true)}
      />

      {/* Error Modal */}
      <SiteErrorModal
        isOpen={isErrorModalOpen}
        onClose={closeFormModal}
        errorMessage="An error occurred. Please try again later."
        details={error}
        buttonText="Close"
      />
    </div>
  );
};

export default NextYearTourSlotReserveForm;
