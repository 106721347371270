import React, { useState } from 'react';
import eventImage from '../../images/SeeMoreFeelMore.jpeg';

const EventAnnouncement: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);


  const redirectToCalendar = () => {
    setIsModalOpen(false);
    window.location.href = "http://adansitravels.com/ads/2025_tour_calendar";
  };

  return (
    <div className="max-w-6xl mx-auto mt-28 bg-gray-900 rounded-noon md:rounded-lg shadow-sm flex flex-col md:flex-row overflow-hidden">
      {/* Image Column */}
      <div className=" md:w-1/3">
        <img
          src={eventImage} // Replace with your image URL
          alt="Event Banner"
          height={"200px"}
          className="md:w-[800px] h-full object-cover rounded-noon md:rounded-l-lg"
        />
      </div>

      {/* Text Column */}
      <div className="w-full md:w-2/3 mt-4 mb-8 md:mx-10 flex flex-col justify-center items-center text-center px-2 md:pl-0 space-y-4">
        <h2 className="w-full md:w-[800px] text-2xl text-center md:text-3xl font-semibold text-gray-50">
        Plan Your Best Year Yet with Our 2025 Travel Calendar!
        </h2>
        <p className="w-full md:w-[700px] px-3 text-gray-100 text-left text-md">
        Mark your travel goals and get ready for incredible experiences! Our 2025 calendar makes planning fun and easy. See More, Feel More. Ready to dive in? Grab it below!
        </p>
        <button
          onClick={redirectToCalendar}
          className="w-40 mt-4 text-center px-3 py-2 bg-green-700  text-white rounded-xl font-semibold transition duration-300 hover:bg-green-600"
        >
        Download Now
        </button>
  
      </div>

    
    </div>
  );
};

export default EventAnnouncement;
