import BgGlassmorphism from "../../../components/BgGlassmorphism/BgGlassmorphism";
import VisaButton from "../../../shared/Button/AdwayBtn";
import React, { useState, Fragment } from "react";
import Accordion from "./resources/accordion";
import Hero from "./Hero";
import vibeImage from  "../../../images/slider-img/namibia.jpg";
import visa0img from  "../../../images/slider-img/visa-img.jpg";
import { Dialog, Popover, Transition } from "@headlessui/react";
import ApplicationForm from "./form/page";

const Page = () => {
  
 let [applyForm, setApplyForm] = useState(false);

 const applyBtn=()=>{
  setApplyForm( !applyForm );
 }

 
  
  const visaPrice = [
    {
      country: "South Africa",
      adansiFees: "$150",
      visaFee: "GHS620",
    },
    {
      country: "Malaysia",
      adansiFees: "$150",
      visaFee: "GHS20",
    },
    {
      country: "Turkey", 
      adansiFees: "$150",
      visaFee: "USD130",
    },
    {
      country: "Europe-Schengen",
      adansiFees: "$200",
      visaFee: "$EUR80",
    },
    {
      country: "UK",
      adansiFees: "$300",
      visaFee: "USD138",
    },
    {
      country: "USA",
      adansiFees: "$300",
      visaFee: "USD160",
    },
    {
      country: "Canada",
      adansiFees: "$400",
      visaFee: "CAD185",
    },
    {
      country: "UAE - Dubai",
      adansiFees: "$130",
      visaFee: "Included",
    },
    {
      country: "Others",
      adansiFees: "$200",
      visaFee: "To Be Advised",
    },
  ];

  const svg = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="w-6 h-6 text-green-700 hover:text-green-500"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z"
      />
    </svg>
  );

  const svg2 = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      className="w-6 h-6 text-red-800 hover:text-red-600"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
      />
    </svg>
  );

  const additionRequirements = [
    {
      question: "EMPLOYEE",
      answer:
        "Introductory letter from the Workplace, Pay slips, Salary Bank Statement and any other personal bank statement if available",
    },
    {
      question: "SELF EMPLOYED",
      answer:
        "I Business Bank Statemen, Personal Bank statement,  Business registration documents from registrar-general, Proof of active business, Tax clearance, is not mandatory for all countries,  Bill of laden if available (if anything has ever been imported in recent period)",
    },
    {
      question: "STUDENTS",
      answer:
        "Official Introduction letter from the university or school including proof of paid school fees and school reports/Transcripts, If self-sponsoring, must be able to provide either A or B requirements, If being sponsored, the sponsor must be able to provide sponsorship letter along with either A or B",
    },
    {
      question: "RETIREES",
      answer:
        "Evidence of last employment before retirement, Bank Account Statement for recent 3 months, SSNIT Statement of Account if available, If being sponsored, the sponsor must be able to provide a sponsorship letter along with either A or B",
    },
    {
      question: "HOUSE WIVES",
      answer:
        "Sponsorship letter from the spouse along with either A or B documents, Marriage certificates and birth certificates of children are mandatory",
    },
    // Add more FAQ items as needed
  ];

  const faq = [
    {
      question: "Do I require travel experience?",
      answer:
        "A good travel experience is required for US and Canada visa application in all situations. For UK and Europe, travel experience is not compulsory but it greatly enhances your chance of the visa. Generally, travel experience increases your credibility and affect your chance of acquiring the visa. Certain countries like UAE – Dubai, South Africa and the like do not require travel experience at all",
    },
    {
      question: "Which Country can I start with?",
      answer:
        "Most people start off with a trip to Dubai, South Africa, Singapore or some African countries to gain some travel experience before applying to UK, Europe, Canada or USA.",
    },
    {
      question: "Will I get refund if my visa is refused?",
      answer:
        "No please. Our visa consultation fee is non-refundable and the visa fees paid to embassies are also non-refundable. Kindly ensure you meet the criteria before you apply.",
    },
    {
      question: "What is the payment plan for the consultation?",
      answer:
        "We require a minimum of 50% before we start the process and full payment before visa submission is done. Most clients find it useful to pay the consultation in full.",
    },
    {
      question: "What is the mode of payment for the visa fee",
      answer:
        "We accept Ghana cedis at the prevailing dollar rate at the time of payment and payment can be done via Ecobank or Mobile money or online. Your visa consultant will send you the payment details.After paying my consultation fees, how long is it valid?All consultation fees paid us is valid for 1 year. This means, we will be assisting you to put your documents together even if it takes a whole year to do so. Once the application is done, the consultation fee is exhausted and new fees will be charged for another application.",
    },
    {
      question: "Do you have a discount for families?",
      answer:
        "We love to promote family travels and therefore we have a generous offer for families.The spouse of the main applicant pays 75% whiles the children if any pays only 50% of the main applicant. This is not applicable for UAE – Dubai visa application. For UAE visas, we give 10% discount for all family visa application.",
    },
    {
      question: "How much account balance should I have?",
      answer:
        "Introductory letter from the Workplace, Pay slips, Salary Bank Statement and any other personal bank statement if available",
    },
    {
      question: "How much account balance should I have?",
      answer:
        "Please note that there is no standard balance required by Embassies for the visa to be granted. You should have enough in your account to demonstrate your ability to offset the cost of your travel and also for basic living expenses after your trip. Most travelers have found a balance not below GH 30,000 to be fair balance to present. However, we have had some people getting the visa with even less amount as other factors are considered in the visa decision making. Our visa consultant will guide you in this regard.",
    },
    {
      question:
        "If I don’t have certain personal documents required like bank statement, can Adansi help?",
      answer:
        "No. we are unable to provide personal bank statement or any documents deemed personal to the applicant. However, if you are working, we will be able to advise you on how to keep your bank accounts or how to register or run your business in order to qualify for the application in the future.",
    },
    {
      question: "If visa is granted, what will be the duration?",
      answer:
        "The visa duration for each destination is determined by the respective consular handling the application and we have no control over that. However, the following duration has been very common with our applications over the years:DUBAI – 1 month or 3 months depending on what you pay for. It could be single or multiple entry depending on what you pay. TURKEY – 1-year single entry visa. SOUTH AFRICA – 3 months single entry. EUROPE: First time or even second time travelers usually get exactly as per the duration of the visa request. Some embassies may also give up to 3 months at their own discretion. Frequent travelers can get multiple entry up to 3years and may not need to pay extra for this. UK: 6 months multiple is the minimum, but frequent UK travelers can request for more by paying extra visa fees. USA: 5 years multiple entry visa is usually the case for B1/B2 visasCANADA: Multiple entry and up to expiry of your passport for adults. Children could be given less duration.",
    },
    {
      question:
        "If I pay the consultation fees of Adansi, does this guarantee my visa",
      answer:
        "We are not able to guarantee the visas for any of our trips as visa decisions are solely at the discretion of the respective Embassy. We however, do all we can to provide professional guidance to all applicants for the visas to be granted. Since 2017, we have assisted over 1000 Ghanaians to attain Europe, UK, US and Canada visas for their travels. We bring this experience in helping you with your visa application once you qualify as per the requirements stated on this page.",
    },
    {
      question:
        "What is the success rate of visa application by Adansi Travels?",
      answer:
        "As per our existing data up to August 2022, below success rate has been achieved by our visa team. UAE, Malaysia and South Africa – 100% (no refusal so far). Europe Schengen and Turkey – 90%. UK Tourist visa – 70%. UK Student visa – 95%. USA Student visa – 90%. USA Tourist visa – 75%. Canada Student visa – 90%. Canada Tourist visa – 80%",
    },
  ];

  const Amenities_demos = [
    { name: "Initial Assessment of personal profile", icon: svg },
    { name: "Flight Itinerary booking ", icon: svg },
    { name: "Hotel Reservation (accommodation cost not included)", icon: svg },
    {
      name: "Travel Insurance booking where applicable (Insurance fees not included)",
      icon: svg,
    },
    { name: "Documents Vetting and Validation", icon: svg },
    { name: "Visa Application Form filling", icon: svg },
    {
      name: "Online Submission of documents where applicable like UK, Canada, USA",
      icon: svg,
    },
    {
      name: "Submission or Interview appointment booking where applicable",
      icon: svg,
    },
    { name: "Interview preparation drill, where applicable", icon: svg },
    { name: "Tour Itinerary if visa is being applied", icon: svg },
    {
      name: "Support in preparing any required letters like introductory or sponsorship letter ",
      icon: svg,
    },
    { name: "la-umbrella-beach", icon: svg },
  ];

  const Amenities_demos2 = [
    { name: "Visa fees", icon: svg2 },
    { name: "Application center fees where applicable", icon: svg2 },
    { name: "Travel Insurance fees", icon: svg2 },
  ];

  const Amenities_demos3 = [
    {
      name: "Valid Passport: The expiring date in your passport must be 6 months or more from the date of travel",
      icon: svg,
    },
    {
      name: "Personal Birth Certificate, mandatory for only specific Europe countries like Germany",
      icon: svg,
    },
    { name: "Marriage certificate, applicable if married", icon: svg },
    {
      name: "Children birth certificates if you have any, doesn’t matter whether they are travelling or not",
      icon: svg,
    },
    {
      name: "Yellow Fever Certificate – Only South Africa and Malaysia visas need this",
      icon: svg,
    },
  ];

  const tableUi = visaPrice.map((visa, index) => {
    return (
      <tbody key={index}>
        <tr className="border-b odd:bg-white even:bg-gray-700  ">
          <th
            scope="row"
            className="px-4 py-4 font-medium text-gray-900 whitespace-nowrap"
          >
            {visa.country}
          </th>
          <td className="px-4 py-4">{visa.adansiFees}</td>
          <td className="px-4 py-4">{visa.visaFee}</td>
        </tr>
      </tbody>
    );
  });


  const renderApplyModalBox = () => {
    return (
      <Transition appear show={applyForm} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={applyBtn}
        >
          <div className="min-h-screen px-1 sm:px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>
  
            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8 h-screen w-full max-w-4xl">
                <div className="inline-flex pb-2 flex-col w-full text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900"
                      id="headlessui-dialog-title-70"
                    >
                     Visa Request Form
                    </h3>
                  </div>
  
    <div className="flex overflow-y-auto flex-col items-center justify-center h-full space-y-4 ">
      <div id="message" className="text-center px-8 sm:px-20 py-10 pb-4">
        We kindly request that you complete this form accurately to help us process your application further.
      </div>
      <div className="h-[500px] w-full  px-2 sm:px-20">
        <ApplicationForm applyBtn={applyBtn} />
      </div>
    </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    );
  };
  

  return (
    <div className={`nc-PageAbout overflow-hidden relative`}>
      <Hero img={ vibeImage } />
      <BgGlassmorphism />
      <div className="container py-16 lg:py-18 space-y-16 lg:space-y-28">
        <div className="flex justify-center align-top md:flex-row flex-col">
          <div className="pr-6">
            <h1 className="text-3xl font-semibold py-6">
              VISA REQUEST PORTAL
            </h1>
            <p className="text-justify">
              We are delighted you have chosen us to assist you with your visa
              processing. We bring our many years of experience, helping
              thousands of Ghanaians travel around the world to help you go
              through the visa process smoothly and successfully. We entreat you to take your time and go through the information
              below.
            </p>
        
          </div>
  
        </div>

        
        <div className="relative overflow-x-auto flex justify-left flex-col">
        <h2 className="text-xl  font-semibold text-neutral-600 md:text-xl xl:text-2xl dark:text-neutral-100 ">
         Pricing
        </h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 mb-6"></div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className=" p-0 sm:p-4">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500">
              <thead className="text-xs text-gray-700 uppercase bg-gray-200">
                <tr>
                  <th scope="col" className="px-4 py-3">
                    COUNTRY
                  </th>
                  <th scope="col" className="px-4 py-3">
                    ADANSI FEES
                  </th>
                  <th scope="col" className="px-4 py-3">
                    VISA FEES
                  </th>
                </tr>
              </thead>
              {tableUi}
            </table>
          </div>
          <div id="colm2" className="p-4">
            <img
              className="w-full"
              src={visa0img}
              alt="Passports with boarding passes"
            />
          </div>
        </div>





        </div>

        {/* ----------------VISA INFO */}
        <div className="listingSection__wrap w-full" style={{ backgroundColor : "#dcdcdc"}}>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="text-neutral-6000 dark:text-neutral-300">
            <span>
              Please note that Visa fees are to be paid either with your
              visa/master card or directly paid to the embassy or application
              center at the time of submission. The Embassy or the application
              center may change the rate at their own discretion and you have to
              pay the current fees at the time of submission.
            </span>
            <p className="font-medium">
              Some Schengen State embassies require a booking fee to be paid to
              VFS (Price varies as per the Schengen state involved and will be
              advised per embassy being used)
            </p>
            <br />
            <br />
            <span>
              <h1 className="font-semibold">WHAT ADANSI FEES COVER</h1>
              <div className="grid grid-cols-1 xl:grid-cols-3 gap-6 text-sm text-neutral-700 dark:text-neutral-300 ">
                {Amenities_demos.filter((_, i) => i < 12).map((item) => (
                  <div key={item.name} className="flex items-center space-x-3">
                    <p>{item.icon}</p>
                    {/* <i className={`text-3xl las ${item.icon}`}></i> */}
                    <span className=" ">{item.name}</span>
                  </div>
                ))}
              </div>
            </span>
            <br /> <br />
            <span>
              <h1 className="font-semibold">WHAT OUR FEES DON’T COVER</h1>
              <div className="grid grid-cols-1 xl:grid-cols-3 gap-6 text-sm text-neutral-700 dark:text-neutral-300 ">
                {Amenities_demos2.filter((_, i) => i < 12).map((item) => (
                  <div key={item.name} className="flex items-center space-x-3">
                    <p>{item.icon}</p>
                    {/* <i className={`text-3xl las ${item.icon}`}></i> */}
                    <span className=" ">{item.name}</span>
                  </div>
                ))}
              </div>
            </span>
            <p className="py-10">
              These costs have been captured in the above table so you can have
              a fare idea of the overall cost involved in the process
            </p>
            <VisaButton onClick={ applyBtn }>Apply Now</VisaButton>
          </div>
        </div>

        {/* ---------------VISA REQUIREMENTS */}
        <div className="listingSection__wrap">
          <div>
            <h2 className="text-2xl font-semibold">Visa Requirement </h2>
            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

            <span className="block mt-2 text-neutral-500 dark:text-neutral-400 ">
              It is important to note that all visa applicants will be asked to
              provide certain personal documents as required by the particular
              embassy. These documents vary depending on which visa is being
              applied
            </span>
          </div>

          <div className="flex  justify-between lg:flex-row md:flex-row flex-col">
            <div className=" text-sm text-neutral-700 dark:text-neutral-300 ">
              <p className="text-justify font-semibold py-2">
                The following requirements apply to all situations:
              </p>
              {Amenities_demos3.filter((_, i) => i < 12).map((item) => (
                <div
                  key={item.name}
                  className="flex items-center space-x-3 justify-start gap-1 p-2"
                >
                  <i className="las la-check-circle text-2xl text-green-700"></i>
                  <span>{item.name}</span>
                </div>
              ))}
            </div>

            <img
           style={{ height : "350px", width : "100px;"}}
              className="w-1/2"
              src="https://img.freepik.com/free-vector/two-passports-with-boarding-passes-tickets-realistic-set-international-air-travel-agency-advertisement-poster_1284-17605.jpg?size=626&ext=jpg&ga=GA1.1.1195576506.1707411751&semt=sph"
              alt=""
            />
          </div>
        </div>
        {/* --------------ADDITIONAL INFO */}
        <div className="container mx-auto p-8">
          <h1 className="text-2xl font-bold mb-4">Additional Requirements</h1>
          <Accordion items={additionRequirements} />

          <p className="font-300 p-4">
            If you do not fall under any of the above categories, it means you
            cannot provide the above documents and therefore we are unable to
            assist you with the visa application.
          </p>


          <p className="font-300 p-4">
            Other documents will be needed based on the embassy and the
            circumstance of the applicant. Our trained consultant will let you
            know any other documents required and will advise you accordingly,
            once you make payment for our services.
          </p>
          <p className="font-300 p-4">
            Adansi Travels cannot obtain personal documents like those stated
            above for the applicant and it’s your responsibility to get ensure
            you can get those before making payment
          </p>
        </div>

        {/* --------------FAQs */}
        <div className="container mx-auto p-0 sm:p-8">
          <h1 className="text-2xl font-bold mb-4">FAQs</h1>
          <Accordion items={faq} />

          <p className="font-300 p-4">
            The success rate shown is for educative purpose and not a guarantee
            as your personal circumstance will determine the visa success chance
            for you. The success rate is calculated based on our overall visa
            application for our collective clients and not individual visa
            success rate.
          </p>

          <div className="flex w-full items-center justify-center">
            <VisaButton onClick={ applyBtn }>Apply Now</VisaButton>
          </div>

        </div>
      </div>
      {renderApplyModalBox()}
    </div>
  );

};

export default Page;
